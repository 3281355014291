/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "~flag-icons/css/flag-icons.min.css";
@import "~primeicons/primeicons.css";
@import "~primeng/resources/themes/bootstrap4-dark-purple/theme.css";

@import "variables";
@import "all";

html,
body {
  height: 100%;
  background-color: #fff;
}
body {
  margin: 0;
  font-family: "Nunito sans";
}
