@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "./variables";

/*Global Styles*/
html,
body {
  height: 100%;
  background-color: $white-color;
}

ul,
ul li {
  list-style: none;
}

*:focus {
  outline: none;
}

.mat-card {
  font-family: "Nunito Sans" !important;
}

body {
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "Nunito Sans";
  // color: $module-fill-color;
  padding: 0px;
  margin: 0px;
  margin: 0 auto;
}

a {
  cursor: pointer !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt18 {
  margin-top: 18px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pt18 {
  padding-top: 18px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.pt45 {
  padding-top: 45px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.pt60 {
  padding-top: 60px !important;
}

.pt70 {
  padding-top: 70px !important;
}

/** to hide background color for api spinner **/

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 75%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
  color: #fff;
  opacity: 0.5;
}

.loader-wrapper {
  .modal-content {
    background: none !important;
    border: none !important;
  }
}

.ngx-datatable.material .datatable-header {
  color: #000 !important;
}

.ngx-datatable.material {
  width: 100% !important;
}
.ngx-datatable.material .datatable-header,
.ngx-datatable.scroll-horz .datatable-body,
.ngx-datatable .datatable-body .datatable-scroll,
.ngx-datatable.material .datatable-body .datatable-body-row.datatable-row-even,
.ngx-datatable .datatable-body .datatable-row-wrapper {
  width: 100% !important;
}

.ngx-datatable.scroll-horz .datatable-body,
.ngx-datatable .datatable-body .datatable-scroll,
.ngx-datatable.material .datatable-body .datatable-body-row.datatable-row-even,
.ngx-datatable .datatable-body .datatable-row-wrapper {
  background: #fff !important;
}

.pl-35 {
  padding-left: 35px;
}

.pl-5 {
  padding-left: 5px;
}
.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled
  .mat-form-field-infix::after {
  color: #212529;
}

.ngx-datatable.material
  .datatable-body
  .datatable-body-row
  .datatable-body-cell {
  padding: 2px !important;
  border-right: 1px solid #ccc;
  border-bottom: none;
  font-size: 12px;
}

.ngx-datatable.fixed-header
  .datatable-header
  .datatable-header-inner
  .datatable-header-cell {
  padding-left: 4px;
  border-right: 1px solid #ccc;
  font-weight: bold;
  color: #000;
}

@media only screen and (min-width: 1901px) {
  .hose-management .ngx-datatable.scroll-horz .datatable-body {
    height: 1040px !important;
  }
}

@media only screen and (min-width: 1701px) and (max-width: 1900px) {
  .hose-management .ngx-datatable.scroll-horz .datatable-body {
    height: 900px !important;
  }
}

@media only screen and (min-width: 1501px) and (max-width: 1700px) {
  .hose-management .ngx-datatable.scroll-horz .datatable-body {
    height: 800px !important;
  }
}

@media only screen and (min-width: 1401px) and (max-width: 1500px) {
  .hose-management .ngx-datatable.scroll-horz .datatable-body {
    height: 700px !important;
  }
}

@media only screen and (min-width: 1301px) and (max-width: 1400px) {
  .hose-management .ngx-datatable.scroll-horz .datatable-body {
    height: 550px !important;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1300px) {
  .hose-management .ngx-datatable.scroll-horz .datatable-body {
    height: 372px !important;
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.dropdown-toggle::after {
  margin-left: 0px !important;
}

.dropdown-toggle::after,
.fa-user:before {
  font-size: 21px;
}

.loader-wrapper {
  .modal-content {
    background: none !important;
    border: none !important;
  }
}

.ngx-spinner-overlay {
  top: 74px !important;
  left: 170px !important;
  z-index: 99998 !important;
}

.show-overlay .ngx-spinner-overlay {
  left: 170px !important;
}

.hide-overlay .ngx-spinner-overlay {
  left: 0 !important;
}

.datatable-row-even {
  background-color: #fff;
}
.datatable-row-odd {
  background-color: #fff;
}
.datatable-body {
  .datatable-row-left {
    background-color: inherit;
  }
}

/* hide scrollbar but allow scrolling */
body {
  scrollbar-width: none !important; /* for Firefox */
  overflow-y: scroll !important;
}

body {
  overflow: hidden;
}

body::-webkit-scrollbar {
  display: none !important; /* for Chrome, Safari, and Opera */
}

/* spinner expand-collpase depending on side bar show/hide***/

.sidebar-wrapper + .main-content .container {
  max-width: 87% !important;
}

.sidebar-wrapper + .main-content .crimp-container {
  max-width: 87% !important;
}

.sidebar-wrapper + .main-content .ngx-spinner-overlay {
  left: 170px !important;
}

.sidebar-wrapper {
  position: fixed;
}

.sidebar-wrapper + .main-content {
  margin-left: 165px !important;
}

.main-content {
  margin-left: 0px !important;
}

.container {
  max-width: 100%;
}

/* profile block */

.profile-block {
  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    margin-right: 10px !important;
  }
  .mat-form-field-appearance-legacy.mat-form-field-disabled
    .mat-form-field-underline {
    background-image: none;
    border-bottom: 1px solid #333;
  }
  .mat-input-element:disabled,
  .mat-form-field-type-mat-native-select.mat-form-field-disabled
    .mat-form-field-infix::after {
    color: #ccc !important;
  }
}

.ngx-datatable
  .datatable-body
  .datatable-row-wrapper:last-child
  .datatable-body-row {
  border-bottom: 1px solid #ccc;
}

.ngx-datatable.material .datatable-header .datatable-row-left,
.ngx-datatable.material .datatable-body .datatable-row-left {
  background-image: none !important;
  transform: none !important;
  position: sticky;
  top: 0;
  left: 0;
}

/*** fix for checkbox background color ***/

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffa500;
}

/*** fix for panel in add hose ***/

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
}

.p-panel .p-panel-header {
  display: none;
}

.p-panel .p-panel-content {
  border: 1px solid #dee2e6;
  background: #fff;
  color: #000;
  padding-top: 0;
}

.crimp-panel {
  .p-panel .p-panel-content {
    padding-bottom: 0 !important;
  }
}

.p-panel .p-panel-header .p-panel-header-icon,
.pi-plus:before,
.pi-minus:before {
  color: #000;
}

.p-toast .p-toast-message.p-toast-message-success,
.p-toast .p-toast-message.p-toast-message-error {
  margin-top: 70px;
}

.p-toast {
  z-index: 99999 !important;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.p-dialog {
  border: none;
  max-height: 93% !important;
  top: 2rem;
  .p-dialog-header {
    border-bottom: 1px solid $modal-header_border-color;
    background: $white-color;
    color: $black-color;
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: $black-color;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child:hover {
  color: $black-color;
}
.p-dialog .p-dialog-content {
  background: $white-color;
  color: $black-color;
  border-radius: 0 0 0.5rem 0.5rem;
  padding: 0.5rem;
}

.p-message.p-message-error .p-message-close {
  border: none;
  margin-left: 4rem;
}
#duplicate-record-modal {
  p-messages {
    margin-left: auto;
  }
  .p-dialog .p-dialog-header-icons {
    margin-left: auto;
  }
  .p-message {
    margin: 0;
  }
  .p-message.p-message-error .p-message-close {
    display: none;
  }
}
